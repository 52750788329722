<template>
  <div class="all">
    <div class="header">
      <div class="headerTop">
        <div>
          <input
            type="file"
            id="file"
            class="filepath"
            accept="image/*"
            multiple
            @change="changeFile($event)"
          />
          <div>
            <img v-if="!avator" src="~assets/images/common/Avator.png" />
            <img v-else-if="src" :src="src" class="img" />
            <img v-else :src="avator" class="img" />
          </div>
        </div>
        <p class="p1">{{ name }}</p>
        <p class="p2">{{ unit }}{{ depName }}</p>
      </div>
      <div class="headerBottom">
        <p>司龄:<span style="margin-right: 16px"></span>{{ workTime }}天</p>
        <div class="centerLine"><div class="xieLine"></div></div>
        <p>当月借支:<span style="margin-right: 16px"></span>{{ payment }}</p>
      </div>
    </div>
    <div class="content">
      <div class="body1">
        <van-cell title="申请借支" is-link to="applyMoney" class="input" />
        <van-cell title="我的借支" is-link to="myBorrow" class="input" />
        <van-cell title="个人薪资" is-link to="myWages" class="input" />
      </div>
      <div class="body1">
        <van-cell
          title="个人资料"
          is-link
          :to="{
            path: 'myInfo',
            query: { avator: avator, name: name, phone: phone },
          }"
          class="input"
        />
        <van-cell title="修改手机" is-link to="Audit" class="input" />
        <van-cell title="修改身份证" is-link to="IDCard" class="input" />
      </div>
      <div class="body1">
        <van-cell title="帮助中心" is-link to="help" class="input" />
        <van-cell title="在线客服" is-link to="server" class="input" />
        <van-cell title="退出" @click="exit" class="input1 input" />
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup } from "vant";
import { user, logout } from "network/login";
import { upLoadImag, userForAvator } from "network/identify";
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      name: "未知",
      depName: "未知部门",
      workTime: "未知",
      payment: "未知",
      avator: "",
      unit: "",
      phone: "",
      src: "",
      file: null,
    };
  },
  methods: {
    changeFile(e) {
      // this.src='';
      this.file = e.target.files;
      console.log(this.file[0]);

      this.file = e.target.files[0];
      var reader = new FileReader();
      var that = this;
      reader.readAsDataURL(this.file);
      reader.onload = function (e) {
        that.src = this.result;
      };

      //访问上传照片的接口

      var formData = new FormData();
      formData.append("image", this.file);
      formData.append("type", "avatar");

      upLoadImag(formData).then((res) => {
        //console.log('fffffffff133',res);

        userForAvator(res.id).then((res) => {
          console.log("fffffffff13555", res);
          Toast.success("修改成功");
        });
        //     if(res.code==200){
        //         console.log('fffffffff1',res);
        // //  Toast.success('上传成功');
        //     }else{
        //           console.log('fffffffff2',res);
        //     //  Toast.fail('上传失败,请重新上传');
        //     }
      });
    },
    exit() {
      logout().then((res) => {
        localStorage.removeItem("token");
        this.$router.push("/login");
      });
      // logout
    },
  },
  created() {
    user().then((res) => {
      this.name = res.name;
      this.avator = res.avatar;
      this.depName = res.department;
      this.unit = res.factory_name;
      this.workTime = res.work_times;
      this.payment = res.borrow_money;
      this.phone = res.mobile;

      localStorage.setItem("phone", this.phone);
      //   console.log(res,'l')
    });
  },
};
</script>

<style scoped>
.filepath {
  position: absolute;
  margin-top: 60px;
  width: 55px;
  opacity: 0;
}
.img {
  border-radius: 50%;
}
.input1 .van-cell__title,
.van-cell__value {
  color: red;
  background-color: transparent !important;
}
/* van-cell{
    background-color: aqua
} */
.all {
}
.input {
  /* color: red; */
  background-color: transparent;
}
.centerLine {
  /* flex: 1; */
  align-self: center;
  align-content: center;
  align-items: center;
}
.xieLine {
  /* margin-right: 2px; */
  /* padding: 0.5px; */
  /* background-color: aqua; */
  width: 1px;
  height: 20px;
  background-color: #f0f0f0;
  /* Rotate div */
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* Internet Explorer */

  -moz-transform: rotate(0deg);
  /* Firefox */
  -webkit-transform: rotate(0deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(0deg);
  /* Opera */
}
.body1 {
  /* height: 150px; */
  width: 94%;
  background-color: #fff;
  border-radius: 5px;
  /* margin: 0 auto; */
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}
.content {
  /* margin-top: 15px; */
  /* background-color: red; */
  /* height: 100%; */
  margin-top: 15px;
  margin-bottom: 20px;
  /* width: 100%; */
}
.headerTop {
  display: flex;
  width: 100%;
  line-height: 1.9;
  align-items: center;
  flex-direction: column;
}

.headerTop .p1 {
  font-size: 18px;
  font-weight: 400;
}
.headerTop .p2 {
  font-size: 13px;
  font-weight: 400;
}
.headerTop p {
  color: #f0f0f0;
}

.headerTop img {
  width: 70px;
  height: 70px;
  margin-top: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;

  background-image: linear-gradient(
    to bottom,
    rgb(239, 70, 32),
    rgb(240, 55, 98)
  );
}
.headerBottom p {
  color: #f0f0f0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  /* background-color: aquamarine; */
  flex: 1;
}
.headerBottom {
  /* padding-left: 7px; */
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-around;
}
</style>