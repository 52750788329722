import axios from './axios'


export function getCapture(mobile) {
    return axios({
        url: 'api/v1/verificationCodes',
        method: 'POST',
        params: {
            mobile
        }
    })
}
export function login(mobile, verification_key, verification_code) {
    return axios({
        url: 'api/v1/authorizations',
        method: 'POST',
        params: {
            mobile,
            verification_key,
            verification_code

        }
    })
}

export function login2(name, id_card) {
    return axios({
        url: 'api/v1/authorizations',
        method: 'POST',
        params: {
            name,
            id_card
        }
    })
}
export function user() {
    return axios({
        url: 'api/v1/user',
    })
}

//退出  删除token

export function logout() {
    return axios({
        url: 'api/v1/authorizations/current',
        method: 'DELETE',

    })
}



// api/v1/user

// export function repair(formData) {
//     return axios({
//         url: '/app/repair',
//         method: 'POST',
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         },
//         data: formData
//     })
// }